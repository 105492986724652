import React from "react"
import { graphql } from "gatsby"
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import Pageable from "../components/pageable"
import MobileDetect from "mobile-detect"

import throttle from 'lodash.throttle';
import axios from "axios";

import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import Layout from "../components/layout"
import SEO from "../components/seo"

class IndexPage extends React.Component {

  constructor(props) {
    super(props);

    this.autoSlider = this.autoSlider.bind(this);
    this.galleryScrollLeft = this.galleryScrollLeft.bind(this);
    this.galleryScrollRight = this.galleryScrollRight.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      md: null,
      pageable: null,
      currentSlide: 1,
      runAutoSlider: null,
      currentSlideGallery: 1,
      currentSlideGalleryProcent: null,
      scrollEnabled: true,
      formName: '',
      formEmail: '',
      formPhone: '',
      formFile: '',
      formMessage: '',
      formFileName: '',
      formFileObject: null,
      formFileNameDefault: props.pageContext.intl.messages.homeFormFileNameDefault,
      statusPage: false,
      onceStatusPage: false,
    }
  }

  addClass = (item, name) => {
    return item.classList.add(name);
  }

  removeClass = (item, name) => {
    return item.classList.remove(name);
  }

  removeAllClass = (item) => {
    return item.className = '';
  }

  autoSlider() {
    var slideOld = document.querySelector('.home--page-1__slide-' + this.state.currentSlide),
        slideNew = (this.state.currentSlide < 4)?(document.querySelector('.home--page-1__slide-' + (+this.state.currentSlide + 1))) : (document.querySelector('.home--page-1__slide-1')),
        self = this;
    slideOld.classList.remove('active');
    slideNew.classList.add('active');

    if(self.state.currentSlide < 4) {
      self.setState({currentSlide: self.state.currentSlide + 1});
    } else {
      self.setState({currentSlide: 1});
    }
  }

  galleryScrollLeft() {
    var self = this;
    if(self.state.currentSlideGallery > 1)
      self.setState({scrollEnabled: true, currentSlideGallery: self.state.currentSlideGallery - 1});
    else {
      self.setState({scrollEnabled: true, currentSlideGallery:  self.state.currentSlideGallery - 1});
      setTimeout(function(){
        self.setState({scrollEnabled: false, currentSlideGallery: 3});
      }, 300);
    }
  }

  galleryScrollRight() {
    var self = this;
    if(self.state.currentSlideGallery < 3)
      self.setState({scrollEnabled: true, currentSlideGallery: self.state.currentSlideGallery + 1});
    else {
      self.setState({scrollEnabled: true, currentSlideGallery:  self.state.currentSlideGallery + 1});
      setTimeout(function(){
        self.setState({scrollEnabled: false, currentSlideGallery: 1});
      }, 300);
    }
  }

  handleChangeState = (param, e) => {
    this.setState({ [param]: e.target.value })
  }

  handleFile(e) {
      var fileName= '', input = e.target;

      if( input.files && input.files.length > 0 ) fileName = input.files[0].name;

      if( fileName )
        this.setState({ 'formFileName': fileName, 'formFileObject': input.files[0] });
      else
        this.setState({ 'formFileName': this.state.formFileNameDefault, 'formFileObject': null });
  }

  resetForm() {
    this.setState({
      formName: '',
      formEmail: '',
      formPhone: '',
      formFileObject: null,
      formFile: '',
      formFileName: this.state.formFileNameDefault,
      formMessage: ''
    });
  }

  handleSubmit(e) {
      e.preventDefault();

      const messageTextLang = this.props.pageContext.intl.messages;

      let formData = new FormData();

      formData.append('formName',this.state.formName);
      formData.append('formEmail',this.state.formEmail);
      formData.append('formPhone',this.state.formPhone);
      formData.append('formMessage',this.state.formMessage);
      formData.append('formFile',this.state.formFileObject);

      axios({
        method: 'post',
        url: '/api/formprocessing.php',
        headers: { 'content-type': 'multipart/form-data' },
        data: formData
      })
      .then(result => {
        this.resetForm();
        store.addNotification({
          title: messageTextLang.notifySuccessTitle,
          message: messageTextLang.notifySuccessText,
          type: "success",
          container: "bottom-center",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          width: 300,
          dismiss: {
            duration: 5000
          }
        });
      })
      .catch(error => {
        store.addNotification({
          title: messageTextLang.notifyErrorTitle,
          message: messageTextLang.notifyErrorText,
          type: "danger",
          container: "bottom-center",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          width: 300,
          dismiss: {
            duration: 5000
          }
        });
      });

  }

  componentDidMount() {
    var self = this;

    this.addClass(document.querySelector('html'), 'home-page');

    window.addEventListener('resize', this.handleResize); 
    this.onResize();

    self.setState({statusPage: true});
  }

  componentDidUpdate() {
    if(this.state.statusPage && !this.state.onceStatusPage) {
      var self = this, md = new MobileDetect(window.navigator.userAgent);
      if (!self.state.runAutoSlider) {
        self.state.runAutoSlider = setInterval( self.autoSlider, 7000);
      }

      if(!md.phone()) {
        self.state.pageable = new Pageable(".home", {
          pips: false,
          events: {
            mouse: false
          },
          onInit: function(data) {
            self.removeAllClass(document.querySelector('body'));
            self.addClass(document.querySelector('body'), 'active-page--'+(data.index+1));
            if(data.index>0) {
              var animated = document.querySelector('#home-page-'+(data.index+1));
              if(!animated.classList.contains("animated")) self.addClass(animated, 'animated');
            }
          },
          onScroll: function(data) {
            self.removeAllClass(document.querySelector('body'));
            self.addClass(document.querySelector('body'), 'active-page--'+(data.index+1));
          },
          onFinish: function(data) {
            if(data.index>0) {
              var animated = document.querySelector('#home-page-'+(data.index+1));
              if(!animated.classList.contains("animated")) self.addClass(animated, 'animated');
            }
          }
        });
      }

      document.querySelector('.header--sidebar__logo-link').addEventListener('click', () => {
        if(self.state.pageable) self.state.pageable.scrollToAnchor('#home-page-1');
      });

      Array.from(document.querySelector('.home--page-1').querySelectorAll('.button')).forEach((el) => {
        el.addEventListener('touchend', () => {
          if(self.state.pageable) self.state.pageable.scrollToAnchor(el.getAttribute('href'));
        });
      });

      self.setState({onceStatusPage: true});
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    this.removeClass(document.querySelector('html'), 'home-page');
    this.removeAllClass(document.querySelector('body'));
    clearInterval(this.state.runAutoSlider);
    if(this.state.pageable) this.state.pageable.destroy();
    this.setState({statusPage: false});
  }

  handleResize = throttle(() => {
    this.onResize();
  }, 500)

  onResize = () => {
    if(window.innerWidth>1440) {
      this.setState({currentSlideGalleryProcent: -1000})
    } else {
      this.setState({currentSlideGalleryProcent: -80})
    }

  }

  render() {

    const home = this.props.data;
    const intl = this.props.pageContext.intl;

    if (this.state && this.state.statusPage) {
      return (
        <Layout>
          <SEO lang={intl.language} title={intl.messages.title} description={intl.messages.description} />

          <div className="home">
            <div id="home-page-1" data-anchor="home-page-1" className="home--page-1">
              <ul className="home--page-1__slider">
                <li className="home--page-1__slide-1 active">
                  <div className="home--page-1__slide-content">
                    <h1 dangerouslySetInnerHTML={{ __html: intl.messages.homeSliderTitle1}} />
                    <p><FormattedMessage id="homeSliderText1" /></p>
                    <p><a href={home.home_link.edges[0].node.publicURL} className="button button--full" target="_blank" rel="noopener noreferrer"><FormattedMessage id="homeSliderButton" /> <i className="arrow--right"></i></a></p>
                  </div>
                </li>
                <li className="home--page-1__slide-2">
                  <div className="home--page-1__slide-content">
                    <h2 dangerouslySetInnerHTML={{ __html: intl.messages.homeSliderTitle2}} />
                    <p><FormattedMessage id="homeSliderText2" /></p>
                    <p><a href={home.home_link.edges[0].node.publicURL} className="button button--full" target="_blank" rel="noopener noreferrer"><FormattedMessage id="homeSliderButton" /> <i className="arrow--right"></i></a></p>
                  </div>
                </li>
                <li className="home--page-1__slide-3">
                  <div className="home--page-1__slide-content">
                    <h2 dangerouslySetInnerHTML={{ __html: intl.messages.homeSliderTitle3}} />
                    <p><FormattedMessage id="homeSliderText3" /></p>
                    <p><a href={home.home_link.edges[0].node.publicURL} className="button button--full" target="_blank" rel="noopener noreferrer"><FormattedMessage id="homeSliderButton" /> <i className="arrow--right"></i></a></p>
                  </div>
                </li>
                <li className="home--page-1__slide-4">
                  <div className="home--page-1__slide-content">
                    <h2 dangerouslySetInnerHTML={{ __html: intl.messages.homeSliderTitle4}} />
                    <p><FormattedMessage id="homeSliderText4" /></p>
                    <p><a href={home.home_link.edges[0].node.publicURL} className="button button--full" target="_blank" rel="noopener noreferrer"><FormattedMessage id="homeSliderButton" /> <i className="arrow--right"></i></a></p>
                  </div>
                </li>
              </ul>
            </div>
            <div data-anchor="home-page-2" className="home--page-2">
              <div className="container">
                <ol className="home--page-2__features">
                  <li>
                    <h3><FormattedMessage id="homeFeaturesTitle1" /></h3>
                    <p><FormattedMessage id="homeFeaturesText1" /></p>
                  </li>
                  <li>
                    <h3><FormattedMessage id="homeFeaturesTitle2" /></h3>
                    <p><FormattedMessage id="homeFeaturesText2" /></p>
                  </li>
                  <li>
                    <h3><FormattedMessage id="homeFeaturesTitle3" /></h3>
                    <p><FormattedMessage id="homeFeaturesText3" /></p>
                  </li>
                </ol>
              </div>
            </div>
            <div data-anchor="home-page-3" className="home--page-3">
              <div className="home--page-3__slider-container">
                <ul className={this.state.scrollEnabled?"home--page-3__slider sliding":"home--page-3__slider"} style={{transform: `translateX(${ this.state.currentSlideGalleryProcent * this.state.currentSlideGallery }${this.state.currentSlideGalleryProcent===-80?'%':'px'})`}}>
                  <li className={this.state.currentSlideGallery===0?'currentGallerySlide cloneGallerySlide':''}>
                    <div className="home--page-3__slide-img" data-before={intl.messages.homeProjectLocation1} data-after="106,9 м.кв.">
                      <Img fluid={home.home_project_3.childImageSharp.fluid} alt={intl.messages.homeProjectPhotoAlt1} />
                    </div>
                    <div className="home--page-3__slide-content">
                      <div className="home--page-3__slide-title">
                        <h3>New House</h3>
                        <Link to="/project_12/" className="link"><FormattedMessage id="homeProjectButton" /> <i className="arrow--right arrow--right-light"></i></Link>
                      </div>
                      <p><FormattedMessage id="homeProjectText1" /></p>
                    </div>
                  </li>
                  <li className={this.state.currentSlideGallery===1?'currentGallerySlide':''}>
                    <div className="home--page-3__slide-img" data-before={intl.messages.homeProjectLocation2} data-after="200 м.кв.">
                      <Img fluid={home.home_project_1.childImageSharp.fluid} alt={intl.messages.homeProjectPhotoAlt2} />
                    </div>
                    <div className="home--page-3__slide-content">
                      <div className="home--page-3__slide-title">
                        <h3>Obolon Residences</h3>
                        <Link to="/project_11/" className="link"><FormattedMessage id="homeProjectButton" /> <i className="arrow--right arrow--right-light"></i></Link>
                      </div>
                      <p><FormattedMessage id="homeProjectText2" /></p>
                    </div>
                  </li>
                  <li className={this.state.currentSlideGallery===2?'currentGallerySlide':''}>
                    <div className="home--page-3__slide-img" data-before={intl.messages.homeProjectLocation3} data-after="175 м.кв.">
                      <Img fluid={home.home_project_2.childImageSharp.fluid} alt={intl.messages.homeProjectPhotoAlt3} />
                    </div>
                    <div className="home--page-3__slide-content">
                      <div className="home--page-3__slide-title">
                        <h3>Lady</h3>
                        <Link to="/project_01/" className="link"><FormattedMessage id="homeProjectButton" /> <i className="arrow--right arrow--right-light"></i></Link>
                      </div>
                      <p><FormattedMessage id="homeProjectText3" /></p>
                    </div>
                  </li>
                  <li className={this.state.currentSlideGallery===3?'currentGallerySlide':''}>
                    <div className="home--page-3__slide-img" data-before={intl.messages.homeProjectLocation1} data-after="106,9 м.кв.">
                      <Img fluid={home.home_project_3.childImageSharp.fluid} alt={intl.messages.homeProjectPhotoAlt1} />
                    </div>
                    <div className="home--page-3__slide-content">
                      <div className="home--page-3__slide-title">
                        <h3>New House</h3>
                        <Link to="/project_12/" className="link"><FormattedMessage id="homeProjectButton" /> <i className="arrow--right arrow--right-light"></i></Link>
                      </div>
                      <p><FormattedMessage id="homeProjectText1" /></p>
                    </div>
                  </li>
                  <li className={this.state.currentSlideGallery===4?'currentGallerySlide cloneGallerySlide':''}>
                    <div className="home--page-3__slide-img" data-before={intl.messages.homeProjectLocation2} data-after="200 м.кв.">
                      <Img fluid={home.home_project_1.childImageSharp.fluid} alt={intl.messages.homeProjectPhotoAlt2} />
                    </div>
                    <div className="home--page-3__slide-content">
                      <div className="home--page-3__slide-title">
                        <h3>Obolon Residences</h3>
                        <Link to="/project_11/" className="link"><FormattedMessage id="homeProjectButton" /> <i className="arrow--right arrow--right-light"></i></Link>
                      </div>
                      <p><FormattedMessage id="homeProjectText2" /></p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="home--page-3__pagination">
                <button className="home--page-3__pagination-left" onClick={this.galleryScrollLeft}><i className="arrow--left-small"></i></button>
                <button className="home--page-3__pagination-right" onClick={this.galleryScrollRight}><i className="arrow--right-small"></i></button>
              </div>
            </div>
            <div data-anchor="home-page-4" className="home--page-4">
              <div className="container">
                <div className="home--page-4__about-us">
                  <div className="home--page-4__about-us-img">
                    <Img fluid={home.home_about_us.childImageSharp.fluid} alt={intl.messages.homeAboutUsPhotoAlt} />
                  </div>
                  <div className="home--page-4__about-us-text">
                    <p>
                      <Link to="/about-us/"><FormattedMessage id="homeAboutUsTitle" /></Link>
                    </p>
                    <h2 className="home--page-4__about-us-title">OL<span>2</span>DIS</h2>
                    <div dangerouslySetInnerHTML={{ __html: intl.messages.homeAboutUsText}} />
                  </div>
                </div>
              </div>
            </div>
            <div id="home-page-5" data-anchor="home-page-5" className="home--page-5">
              <div className="container">
                <div className="home--page-5__container">
                  <h2 dangerouslySetInnerHTML={{ __html: intl.messages.homeFormTitle}} />
                  <form encType="multipart/form-data" method="post" onSubmit={this.handleSubmit} className="home--page-5__form">
                    <div className="home--page-5__form-col-1">
                      <div className="home--page-5__form-row"><input type="text" name="formName" value={this.state.formName} onChange={this.handleChangeState.bind(this, 'formName')} required="required" placeholder={intl.messages.homeFormPlaceholder1} /></div>
                      <div className="home--page-5__form-row"><input type="email" name="formEmail" value={this.state.formEmail} onChange={this.handleChangeState.bind(this, 'formEmail')} required="required" placeholder={intl.messages.homeFormPlaceholder2} /></div>
                      <div className="home--page-5__form-row"><input type="tel" name="formPhone" value={this.state.formPhone} onChange={this.handleChangeState.bind(this, 'formPhone')} pattern="^[0-9-+\s()]*$" required="required" placeholder={intl.messages.homeFormPlaceholder3} /></div>
                      <div className="home--page-5__form-row"><input type="file" id="formFile" value={this.state.formFile} onChange={this.handleFile} name="formFile" accept="application/pdf, image/*" /><label htmlFor="formFile" data-null={intl.messages.homeFormFileNameDefault}><FormattedMessage id="homeFormPlaceholder4" /> <span>{this.state.formFileName?this.state.formFileName:this.state.formFileNameDefault}</span></label></div>
                    </div>
                    <div className="home--page-5__form-col-2">
                      <div className="home--page-5__form-row">
                        <textarea name="formMessage" required="required" placeholder={intl.messages.homeFormPlaceholder5} id="formMessage" onChange={this.handleChangeState.bind(this, 'formMessage')} value={this.state.formMessage} />
                      </div>
                    </div>
                    <div className="home--page-5__form-col-3">
                      <input type="submit" className="button button--full" value={intl.messages.homeFormButton} />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

        </Layout>
      )
    } else {
      return null;
    }
  }
}

export default injectIntl(IndexPage)

export const pageQuery = graphql`
  query {
    home_about_us: file(relativePath: { eq: "home/about_us.jpg" }) {
      childImageSharp {
        fluid(quality:75, maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    home_project_1: file(relativePath: { eq: "home/preview-01.jpg" }) {
      childImageSharp {
        fluid(quality:75, maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    home_project_2: file(relativePath: { eq: "home/preview-02.jpg" }) {
      childImageSharp {
        fluid(quality:75, maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    home_project_3: file(relativePath: { eq: "home/preview-03.jpg" }) {
      childImageSharp {
        fluid(quality:75, maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    home_link: allFile(filter: { relativePath: { eq: "example.pdf" } }) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
  }
`